import { Segment } from 'semantic-ui-react';
import ReviewCard from "../../../components/ReviewCard/ReviewCard"
import { useMediaQuery } from 'react-responsive';
import { primaryGrey } from '../../../utils/colors';
import { homepageReviews } from '../../../utils/dummyArray';

const HomepageReviews = () => {
    const md = useMediaQuery({ query: "(min-width:1000px)" });
  
    return (
        <div>
            <h1 style={{marginBottom: '5rem', marginTop: '4rem'}}>Recenzii</h1>
        <Segment
            style={{
                backgroundColor: primaryGrey,
                padding: 0,
                margin: 'auto',
                border: 0,
                marginBottom: '15rem',
                boxShadow: 'none',
                width: '80%',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: md ? 'flex-start' : 'center'
            }}
        >
            {homepageReviews.map((review, index) => (
                <div key={index} style={{ flex: md ? '0 0 50%' : '100%', maxWidth: md ? '50%' : '100%', padding: '0.5rem', marginBottom: md ? '1rem' : '0.5rem' }}>
                    <ReviewCard data={review} />
                </div>
            ))}
        </Segment>
        </div>
    )
}

export default HomepageReviews;
