import { useNavigate } from 'react-router-dom';
import './PersonCard.css';

const PersonCard = ({ data }) => {
    return (
        <div className='app-person-card-container'>
            {data && data.map((item, index) => (
                <SinglePersonCard key={index} person={item} />
            ))}
        </div>
    );
}

const SinglePersonCard = ({ person }) => {

    const navigate = useNavigate()

    return (
        <div id="login-container"
            style={{
                height: person.cv ? '400px' : '200px' 
            }}
        >
            <div className="profile-img"
                style={{
                    background: `url(${person.img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            ></div>

            <h1>
                {person.name}
            </h1>

            <div className="description">
               {person.description}
            </div>

            <div className="social"
                style={{
                    display: person.insta ? 'flex' : 'block',
                    justifyContent: person.insta ? 'space-between' : 'center' ,
                }}
            >
                <a href={person.fb} target="_blank" >Facebook</a>
                {person.insta ? 
                    <a href={person.insta} target="_blank">Instagram</a>
                : null }
            </div>
            
            {person.cv ? 
            <button className='to-cv'
                onClick={() => navigate(`/cv/${person.name}`)}
            >Spre CV</button>
            : null }

        </div>
    );
}

export default PersonCard;