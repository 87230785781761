import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <Header />
      <section className="location">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2791.041569464722!2d24.56535471583813!3d46.54741190413424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474bb7f9ef86ebf1%3A0xc2f191b71e4baab6!2sIncubator13!5e0!3m2!1sen!2sro!4v1644309208129!5m2!1sen!2sro"
          width="720"
          height="445"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
          title="contact"
        ></iframe>
      </section>

      <section className="contact-us">
        <div className="row">
          <div className="contact-col">
            <div>
              <i className="fa fa-home"></i>
              <span>
                <h5>Strada Avram Iancu, Bastionul Porții</h5>
                <p>Cetatea medievală, Târgu-Mureș, Mureș</p>
              </span>
            </div>
            <div>
              <i className="fa fa-phone"></i>
              <span>
                <h5>+40 723 314 899</h5>
              </span>
            </div>
            <div>
              <i className="fa fa-envelope-o"></i>
              <span>
                <h5>incubator13ro(at)gmail.com</h5>
                <p>Trimite un mail cu întrebările tale</p>
              </span>
            </div>
          </div>
          {/* <div className="contact-col">
            <form method="post" action="contact-form-handler.php">
              <input type="text" name="name" placeholder="Nume" required />
              <input type="email" name="email" placeholder="Email" required />
              <input
                type="text"
                name="subject"
                placeholder="Subiect"
                required
              />
              <textarea
                rows="8"
                name="message"
                placeholder="Mesaj"
                required
              ></textarea>
              <button type="submit" className="hero-btn red-btn">
                Trimite
              </button>
            </form>
          </div> */}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
