import EventCard from "../../components/EventCard/EventCard";
import Footer from "../../components/Footer/Footer";
import HeaderComponent from "../../components/Header/Header";

import { spectacoleViitoare, spectacoleAnterioare } from "../../utils/dummyArray";

import './Evenimente.css'

const Evenimente = () => {
    return (
        <div>
            <HeaderComponent />

            <h1 style={{textAlign: 'center', marginBottom: '4rem', color: '#73756f'}}>Evenimente Viitoare</h1>

            <div className="event-card-container">
                <EventCard data={spectacoleViitoare}/>
            </div>

            <h1 style={{textAlign: 'center', marginBottom: '4rem', color: '#73756f'}}>Evenimente Anterioare</h1>

            <div className="event-card-container">
                <EventCard data={spectacoleAnterioare}/>
            </div>
            
            <Footer />
        </div>
    );
}

export default Evenimente;
