import { Card } from 'semantic-ui-react';

import { 
    primaryGreen,
    primaryGrey,
    primaryRed,
    primaryYellow
} from '../../utils/colors';

import { 
    headingFontSize,
    headingFontSize2,
    mediumSize2
} from '../../utils/fonts';

const ReviewCard = ({ data }) => {
    

    return (
        <Card style={{ width: '100%', minHeight: '15rem' }}>
            <div className="content" style={{ backgroundColor: primaryGreen}}>
                <div className="header" style={{ backgroundColor: primaryGreen, color: primaryGrey, fontSize: headingFontSize, borderBottom: `2px solid ${primaryGrey}`}}>{data.name}</div>
                <div className="description" style={{ backgroundColor: primaryGreen}}>
                    <p style={{ backgroundColor: primaryGreen, color: primaryGrey, fontSize: mediumSize2}}>{data.review}</p>
                </div>
            </div>

            <div className="extra content" 
                style={{ 
                    backgroundColor: primaryGreen,
                }}
            >
                <span className="like" 
                    style={{ 
                        fontSize: headingFontSize2, 
                        color: primaryGrey, 
                        backgroundColor: primaryGreen,
                        marginRight: '15px'
                    }}
                >
                    <i className="like icon" 
                        style={{ 
                            color: primaryRed, 
                            backgroundColor: primaryGreen,
                            }}>
                    </i>
                    {data.likes}
                </span>

                <span className="star" 
                    style={{ 
                        fontSize: headingFontSize2, 
                        color: primaryGrey,
                        backgroundColor: primaryGreen,
                        }}
                >
                    <i className="star icon" 
                        style={{ 
                            color: primaryYellow, 
                            fontSize: headingFontSize2,
                            backgroundColor: primaryGreen,
                            }}>
                    </i>
                    {data.fav}
                </span>
            </div>


        </Card>
    );
}

export default ReviewCard;
