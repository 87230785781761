import { useState, useEffect } from 'react';
import { Menu, Sidebar, Segment, Image, Dropdown } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { primaryGreen } from '../../utils/colors';
import { iconsSize } from '../../utils/fonts';
import Logo from '../../utils/GeneralImages/Logo.png';

const Overlay = () => {
    return (
        <div style={{
            position: "fixed",
            height: "110vh",
            width: "100%",
        }} 
        />
    );
};

const HeaderMb = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [visible, setVisible] = useState(false);
    const [icon, setIcon] = useState(<FontAwesomeIcon icon={faBars} style={{ fontSize: iconsSize, backgroundColor: primaryGreen }} />);
    const [iconRadius, setIconRadius] = useState("bars");
    const [activeItem, setActiveItem] = useState("acasa");

    const handleItemClick = (e, { name }) => {
        setActiveItem(name);
        navigate(name === "acasa" ? "/" : `/${name}`);
        hideSidebar();
    };

    const handleLogoClick = () => {
        navigate("/");
        hideSidebar();
    };

    const hideSidebar = () => {
        setIcon(<FontAwesomeIcon icon={faBars} style={{ fontSize: iconsSize, backgroundColor: primaryGreen }} />);
        setVisible(false);
        setIconRadius('bars');
    };

    const showSidebar = () => {
        setIcon(<FontAwesomeIcon icon={faXmark} style={{ fontSize: iconsSize, backgroundColor: 'transparent' }} />);
        setVisible(true);
        setIconRadius('mark');
    };

    const toggleSidebar = () => {
        visible ? hideSidebar() : showSidebar();
    };

    useEffect(() => {
        const path = location.pathname.substring(1);
        setActiveItem(path || "acasa");
    }, [location.pathname]);

    return (
        <Segment
            style={{
                backgroundColor: primaryGreen,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: 0,
            }}
        >
            {visible && <Overlay />}

            <img 
                src={Logo} 
                alt="logo" 
                style={{    
                    width: '150px', 
                    height: '71px', 
                    backgroundColor: 'transparent',
                    cursor: 'pointer'
                }}
                onClick={handleLogoClick}
            />

            <Menu
                size="tiny"
                borderless
                attached
                style={{ backgroundColor: '#73756f', border: 'none', width: '100px'}}
            >
                <Menu.Menu position='right'
                    style={{ backgroundColor: '#73756f'}}
                >
                    <Menu.Item 
                        onClick={toggleSidebar} 
                        style={{ 
                            backgroundColor: primaryGreen,
                            borderRadius: iconRadius === 'bars' ? '0' : '43px'
                        }}
                    >
                        {icon}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            <Sidebar 
                as={Menu}
                animation='overlay'
                icon='labeled'
                inverted
                vertical
                visible={visible}
                direction='left'
                style={{
                    backgroundColor: primaryGreen,
                }}
            >
                <Menu.Item name='acasa' id='acasa' active={activeItem === 'acasa'} onClick={handleItemClick}>
                    Acasa
                </Menu.Item>
                <Menu item text='Proiect' name='proiect' active={activeItem.startsWith('proiect')}>
                        <Menu.Item name='proiect-2024' onClick={handleItemClick} >Proiect 2024</Menu.Item>
                        <Menu.Item name='proiect-2023' onClick={handleItemClick} >Proiect 2023</Menu.Item>
                </Menu>
                <Menu.Item name='evenimente' id="evenimente" active={activeItem === 'evenimente'} onClick={handleItemClick}>
                    Evenimente
                </Menu.Item>
                <Menu.Item name='noaptea-muzeelor' id="noaptea-muzeelor" active={activeItem === 'noaptea-muzeelor'} onClick={handleItemClick}>
                    Noaptea Muzeelor
                </Menu.Item>
                <Menu.Item name='echipa' id="echipa" active={activeItem === 'echipa'} onClick={handleItemClick}>
                    Echipa
                </Menu.Item>
                <Menu.Item name='despre-noi' id="despre-noi" active={activeItem === 'despre-noi'} onClick={handleItemClick}>
                    Despre Noi
                </Menu.Item>
                <Menu.Item name='contact' id="contact" active={activeItem === 'contact'} onClick={handleItemClick}>
                    Contact
                </Menu.Item>
            </Sidebar>
        </Segment>
    );
};

export default HeaderMb;
