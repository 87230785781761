import React from "react";

import HeaderComponent from "../../components/Header/Header";

import { atelierDetalii2 } from "../../utils/dummyArray";
import ReactPlayer from 'react-player';

import { useMediaQuery } from 'react-responsive';

import trailerExploziv from '../../utils/videos/trailerExploziv.mp4'


import './Proiect.css';
import Footer from "../../components/Footer/Footer";

const Proiect2 = ({color}) => {

    const sm = useMediaQuery({ query: "(max-width:400px)" })
    const sm2 = useMediaQuery({ query: "(max-width:500px)" })
    const md = useMediaQuery({ query: "(max-width:600px)" })
    const md2 = useMediaQuery({ query: "(max-width:800px)" })
    const xxl = useMediaQuery({ query: "(max-width:900px)" })
    const xxl2 = useMediaQuery({ query: "(min-width:1500px)" })

    const playerWidth = () => {
        if (sm) {
            return 250;
        } else if (sm2) {
            return 350;
        } else if (md) {
            return 400;
        } else if (md2) {
            return 500;
        } else if (xxl) {
            return 700;
        } else if (xxl2) {
            return 1200;
        } else {
            return 1000;
        }
    };

    const titleSize = () => {
        if(sm) { 
            return 16
        } else if(sm2) {
            return 20
        } else if(md2) {
            return 22
        } else if(xxl) {
            return 38
        } else if(xxl2) {
            return 38
        }
    }

    const subTitleSize = () => {
        if(sm) { 
            return 12
        } else if(sm2) {
            return 14
        } else if(md2) {
            return 16
        } else if(xxl) {
            return 18
        } else if(xxl2) {
            return 22
        }
    } 


    const imageWidth = () => {
        if(sm) { 
            return 60
        } else if(sm2) {
            return 100
        } else if(md2) {
            return 120
        } else if(xxl) {
            return 140
        } else if(xxl2) {
            return 150
        }
    }

    const imageHeight = () => {
        if(sm) { 
            return 60
        } else if(sm2) {
            return 100
        } else if(md2) {
            return 120
        } else if(xxl) {
            return 140
        } else if(xxl2) {
            return 150
        }
    }

    const nameSize = () => {
        if(sm) { 
            return 14
        } else {
            return 18
        }
    }

    const descriptionSize = () => {
        if(sm) { 
            return 10
        } else if(sm2) {
            return 12
        } else {
            return 14
        }
    }

    const cvSize = () => {
        if(sm) { 
            return 10
        } else if(sm2) {
            return 12
        } else {
            return 16
        }
    }


    return (
        <div >
            <HeaderComponent />

            <div className="main-container-content">
                <h1 className='elise'
                    style={{fontSize: titleSize(), color: 'rgb(115, 117, 111)'}}
                >Exploziv ART (2024) </h1>
                <h2 className='incub-2' style={{fontSize: subTitleSize()}}>ExplozivArt (ediția 2) este un proiect de educație culturală nonformală, bilingv, care se adresează elevilor din liceele târgumureșene. </h2>
                <h2 className='incub-2' style={{fontSize: subTitleSize()}}> Pornit din dorința de a le oferi o alternativă la programele școlare, axate în principal pe noțiuni teoretice, ExplozivArt urmărește, printr-o serie de ateliere interdisciplinare și a producerii unui spectacol de teatru, implicarea lor în activităi practice și interactive care să îi ajute să se înțeleagă mai bine pe ei și comunitatea din care fac parte, să le dezvolte competențe necesare în mai multe domenii de activitate, nu doar în cele artistice și să creeze un cadru în care să poată vorbi despre problemele specifice vârstei lor simțindu-se în siguranță.</h2>
            </div>

            <div> 
            <h3
                style={{
                    fontSize: '24px',
                    marginTop: '8rem'
                }}
            >Trainerii nostri</h3>

            {atelierDetalii2 && Object.values(atelierDetalii2).map((item, index) => (
                <div className="container-atelier-left" key={index}>
                    <div className="image-container-atelier-left">
                        <img src={item.img} alt="" style={{width: imageWidth(), height: imageHeight()}}/>
                    </div>
                    <div className="information-container-atelier-left">
                        <h3 style={{fontSize: nameSize()}}>{item.title}</h3>
                        <h3 style={{fontSize: nameSize(), color: 'black'}}>{item.name}</h3>
                        <h5 style={{fontSize: descriptionSize()}}>{item.descriere}</h5>
                        <h5 style={{fontSize: descriptionSize()}}>{item.desscriere1}</h5>
                        <h5 style={{fontSize: descriptionSize()}}>{item.descriere2}</h5>
                        <h5 style={{fontSize: descriptionSize()}}>{item.descriere3}</h5>
                        <h5 style={{fontSize: descriptionSize()}}>{item.descriere4}</h5>
                        <h5 style={{fontSize: descriptionSize()}}>{item.descriere5}</h5>
                        <h5 style={{fontSize: descriptionSize()}}>{item.descriere6}</h5>
                    {item.name !== "" ? (
                        <a href={`/cv/${item.name}`} className="link-cv" style={{fontSize: cvSize()}}>
                        Spre CV coordonator
                        </a>
                    ) : null}
                    </div>
                </div>
                ))}
            </div>
           

            {/* <h3
                style={{
                    fontSize: '24px',
                    marginTop: '8rem'
                }}
            >
                Recenzii
            </h3>

            <div className="testimonials-container">
                <div className="post">
                    <div className="post-header">
                        <span className="post-title">Adriana Tomulețiu</span>
                    </div>
                    <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                    {review1}
                    </div>
                </div>
            </div>


            <div className="testimonials-container">
                <div className="post">
                    <div className="post-header">
                        <span className="post-title">Codruța Pop</span>
                    </div>
                    <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                    {review2}
                    </div>
                </div>
            </div>


            <div className="testimonials-container" style={{marginBottom:'5rem'}}>
                <div className="post">
                    <div className="post-header">
                        <span className="post-title">Mădălina Hondrea</span>
                    </div>
                    <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                    {review3}
                    </div>
                </div>
            </div> */}

            <div
            style={{
                width: '80%',
                margin: 'auto',
                fontSize: '18px',
                color: '#73756f',
                marginBottom: '4rem',
                marginTop: '8rem !important'
            }}
            >
                Proiect co-finanțat de Municipiul Târgu Mureş în sesiunea 2023.
                Proiectul nu reprezintă în mod necesar poziția Municipiului Târgu Mureş şi a Consiliului local care nu sunt responsabili de conţinutul proiectului sau de modul în care rezultatele acestuia pot fi folosite. Acestea sunt în întregime responsabilitatea beneficiarului finanţării”

            </div>

            <div className="center aligned" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5rem' }}>
                    <ReactPlayer 
                        url={trailerExploziv} 
                        // height={ xxl ? 550 : 500 } 
                        width={ playerWidth() }
                        controls={true}
                        playing={true}
                        loop={true}
                        muted={true}
                        onReady={() => true}
                    />
                </div>

            <Footer />
        </div>

    )
}


export default Proiect2;


const review1 = `Acest spectacol este unul cu adevărat ”exploziv”, care te zdruncină ca părinte. Te zdruncină atât de puternic, încât te trezește la realitate, îndemnându-te să te uiți în ”oglindă”, să te autoanalizezi: ”Ce fel de părinte sunt? Care sunt nevoile mele? Care este sensul meu? Care este atitudinea mea față de cei din jur, față de copiii mei? Oare sunt suficient de atent(ă) la nevoile lor? La trăirile lor? Sunt lângă ei atunci când au nevoie de mine? Sunt prezent(ă)?” 
Pentru că tot ceea ce suntem noi, ca adulți, ca părinți, toate trăirile, nevoile, traumele noastre, toate reacțiile și atitudinile noastre, își pun amprenta asupra copiilor noștri, asupra relației pe care o avem cu ei, uneori cu consecințe nefaste.

Este un spectacol ”exploziv”, care te zdruncină pe tine ca profesor și te îndeamnă să te interoghezi: Cum sunt eu ca profesor? Cum mă văd elevii mei? Oare cine sunt ei cu adevărat? Oare care sunt adevăratele lor nevoi? Ce trecut au, ce experiențe anterioare? Ce le place? Ce nu le place? Oare timpul petrecut aici, cu mine, este un timp de calitate pentru ei? Ceea ce i-am învățat le folosește cu adevărat în viață? Oare se simt în siguranță la școală? Și multe altele... 
Pentru că el, profesorul, nu este doar un transmițător de cunoștințe, un formator de competențe și abilități la elevi; e important să fie mai mult decât atât: să fie aproape de elevi, să-i ajute să înțeleagă valoarea educației, să-i facă să iubească școală, să le dezvolte încredere în ei, în resursele lor interioare, să le cultive optimismul, nu frica, starea de bine, nu stresul, să-i facă se simtă prețuiți pentru ceea ce sunt, exact așa cum sunt. El profesorul, nu lucrează cu materiale de construcții, unde poate se mai permit anumite rebuturi, care se pot remedia. 
Lucrează cu suflete, cu ființe umane, și un suflet odată distrus, este greu de recuperat.

Este un spectacol ”exploziv” pentru adolescenți; este ca un strigăt de ajutor: ”Sunt aici! Uita-te la mine! Mă vezi? Nu știi nimic despre mine!” Un strigăt de ajutor îndreptat spre părinți, spre profesori, spre colegi, spre societate în general. Este un strigăt care are rostul de a te trezi din indiferență, din nepăsare, din ”adormire”, care te îndeamnă să fii prezent, aici și acum, și să construiești, înainte de a fi prea târziu, o lume mai frumoasă, o lume mai bună.

Acest spectacol îl simt ca pe o lecție din care toți avem de învățat – părinți, profesori, elevi. O lecție pe care ne-au oferit-o 17 adolescenți minunați care, cu siguranță, și-au dedicat multe ore din timpul lor pentru a o pune în scenă! 

Este o lecție atât de bine scrisă de Elise Wilk, care reflectă probleme ale adolescenților, de la conflictele lor interioare până la conflictele cu cei din jur, nevoia lor de a ieși în evidență, de fi văzuți, ascultați, valorizați, nevoia de suport parental și psiho-educațional. Este o lecție despre viață, despre dorințe neîmplinite, despre drame umane, care te îndeamnă la reflecție. 

Este o lecție pe care cei 17 adolescenți au învățat-o alături de niște profesori minunați – actorii Anca Loghin și Rareș Budileanu – a căror implicare este, la rândul ei, o lecție pentru mulți dintre noi. Ați reușit prin acest proiect să ne arătați cât de valoroși sunt adolescenții, că interiorul lor e plin de resurse, iar dacă le scoți la iveală, le cultivi, le potențezi, creezi miracole. Și da, spectacolul ”Exploziv” este un miracol. 

Felicitări!`;


const review2 = `Viața netrăită a adulților este o grea povară aruncată pe umerii copiilor. Toate acele lucruri dosite în sertarele sufletului nostru, doruri, neîmpliniri, frustrări, dorințe înăbușite se revarsă grav tocmai asupra celor pentru care pretindem ca “ne sacrificăm”.

Piesa “Exploziv”, în regia lui Rareș Budileanu este, din punctul meu de vedere, un manifest! Exprimat prin teatru, pune pe tapet cele mai mari provocări ale adolescentului din generația “selfie" , “tik tok “ și “Nu am timp! Vorbim mâine” : uciderea creativității, sinuciderea, bullyng-ul, drogurile, indiferența, criza de identitate, dorința de apartenență și drama generată de lipsa ei.

O piesă care trebuie văzută de părinți și profesori în egală măsură. O piesă care intrigă, care schimbă, care trezește. Care ne arată ca singurele modele adevărate ale copiilor noștri suntem noi, adulții din jurul lor, care conducem prin puterea exemplului și mai puțin a cuvântului. 

Felicitări Rares Budileanu! Felicitări Anca Loghin! Felicitări adolescenților care au transmis în mai puțin de două ore ceea ce ne prefacem ca nu vedem de ani de zile!`;


const review3 = `Un spectacol foarte interesant, excelent jucat de copiii din proiect! S-a (intre)vazut munca din spatele lui, atât a protagoniștilor, cât și a celor care i-au îndrumat. Tema piesei este în mod cert de actualitate și ne amintește cât sunt de fragili și deopotrivă puternici adolescenții. Felicitări pentru proiect, Rares Budileanu și Anca Loghin!`;
