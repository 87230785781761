import React from 'react';

import { Segment } from 'semantic-ui-react';

import EventTicket from '../../../components/EventTicket/EventTicket';

import { primaryGrey } from '../../../utils/colors';


const HomepageShows = () => {
  return (
    <Segment 
        style={{
          width: '80%', 
          margin: 'auto',  
          border: 'none', 
          boxShadow: 'none', 
          // marginTop: '5rem',
          backgroundColor: primaryGrey
        }}
    >
       <h1 style={{marginBottom: '5rem'}}>Spectacole Viitoare</h1>
      <EventTicket />
    </Segment>
  )
}

export default HomepageShows
