import React from "react";
import ReactPlayer from 'react-player';

import KaboomMain27 from '../../utils/videos/KaboomMain27.mp4';
import BikeVideo from '../../utils/videos/BikeVideo.mp4'

import { 
    Grid,
    Header
} from 'semantic-ui-react';

import Martie from '../../utils/videos/Martie.mp4';
import Mai from '../../utils/videos/mai.mp4';
import trailerHomepage from '../../utils/videos/trailerHomepage.mp4'
import videoNou from '../../utils/videos/videonou.mp4';

import { 
    primaryFontFamilyBold,
    baseFontSize
} from "../../utils/fonts";
import HompeageMuzeu from "../HomepageMuzeu/HomepageMuzeu";


const HerosectionMb = () => {

    return (
        <Grid style={{width: '100%'}}>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2' style={{ fontFamily: primaryFontFamilyBold }}>
                        Sigur - toată lumea spune povești. Dar puține povești se spun pe loc și puțini îți fac loc în povestea lor. 
                    </Header>
                    <p style={{ fontSize: baseFontSize}}>
                        În lumi tot mai deconectate, am deschis un spațiu independent în <strong> Turnul Porții (din Mai 2022) </strong>, în care să facem lucrurile aici și acum. La <strong> Incubator 13 </strong> improvizăm lumi noi în <strong> KaBoom Unlimited Impro </strong>. Aducem muzică mai puțin ascultată, dar care nu trebuie să scape ascultării. Punem culori pe pânză în experiențe live. Completăm <strong> educația formală </strong>e și o regândim prin <strong> ateliere practice </strong>, conectate printr-o constantă: <strong> creativitatea </strong>. Mergem în locuri mai puțin explorate, prin spectacole de teatru diverse tematic. Toate astea împreună cu tine. Din 2018, spunem lucruri pe care le simțim importante. Și încă mai avem multe de spus.
                    </p>

                </Grid.Column>
            </Grid.Row>

            <Grid.Row >
                <Grid.Column className="center aligned" style={{ display: 'flex', justifyContent: 'center' }}>
                    <ReactPlayer 
                        url={BikeVideo} 
                        height={500} 
                        width={400}
                        // controls={true}
                        playing={true}
                        loop={true}
                        muted={true}
                        autoPlay={true}
                        onReady={() => true}
                        style={{
                            background: 'transparent'
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default HerosectionMb;